import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";

import "./App.css";

import { IsAuthenticatedContext } from "./components/IsAuthenticatedContext";

const Login = lazy(() => import("./components/Login"));
const Search = lazy(() => import("./components/Search"));
const Help = lazy(() => import("./components/Help"));
const User = lazy(() => import("./components/User"));
const Logout = lazy(() => import("./components/Logout"));
const PasswordReset = lazy(() => import("./components/PasswordReset"));
const PasswordResetConfirm = lazy(
  () => import("./components/PasswordResetConfirm"),
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const localAuth = localStorage.getItem("isAuth");

  return (
    <Container fluid>
      <BrowserRouter
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true,
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <IsAuthenticatedContext.Provider
            value={{ isAuthenticated, setIsAuthenticated }}
          >
            <Routes>
              <Route index element={<Login />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route
                path="/password-reset-confirm/:param1/:param2/"
                element={<PasswordResetConfirm />}
              />
              {(isAuthenticated || localAuth) && (
                <>
                  <Route path="/search" element={<Search />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/user" element={<User />} />
                  <Route path="/logout" element={<Logout />} />
                </>
              )}
            </Routes>
          </IsAuthenticatedContext.Provider>
        </Suspense>
      </BrowserRouter>
    </Container>
  );
}

export default App;
